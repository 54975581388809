import { MODAL_TYPES } from '~/config/constant'

export default () => {
  const { $bvModal } = useNuxtApp()
  const route = useRoute()

  const closeModal = (id: string) => $bvModal.hide(id)
  const openModal = (id: string) => $bvModal.show(id)

  const closeAllModal = () => {
    Object.values(MODAL_TYPES).forEach(closeModal)
  }

  const openModalOne = (id: string) => {
    closeAllModal()
    setTimeout(() => openModal(id), 200)
  }

  const closeExceptModal = (id: string) => {
    Object.values(MODAL_TYPES).forEach((item) => {
      if (item !== id) { closeModal(item) }
    })
  }

  const openModalWithNavigate = (id: string, type: string, openUrl?: string, navigateUrl?: string) => {
    const route = useRoute()
    if (!openUrl && !navigateUrl) {
      navigateTo({
        path: location.pathname,
        query: { ...route.query, popup: type }
      })
    } else if (!navigateUrl && openUrl) {
      navigateTo({
        path: location.pathname,
        query: { ...route.query, popup: type, openUrl }
      })
    } else {
      navigateTo({
        path: location.pathname,
        query: { ...route.query, popup: type, navigateUrl }
      })
    }

    closeAllModal()
    openModal(id)
  }

  return {
    closeModal,
    openModal,
    closeAllModal,
    openModalOne,
    closeExceptModal,
    openModalWithNavigate
  }
}
